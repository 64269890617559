<template>
  <div class="not-found-wrapper page">
    <h1>Ой! Страница не найдена! :(</h1>
  </div>
</template>

<script>

export default {
  name: 'NotFound'
}
</script>

<style lang="scss" src="./style.scss" />
